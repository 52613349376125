import React from 'react';
import { useJsApiLoader } from '@react-google-maps/api';
import GPSMap from './GPSMap';

const API_KEY = process.env.REACT_APP_GOOGLE_API;
console.log(API_KEY)
function MapLoader() {
  const { isLoaded } = useJsApiLoader({
    id: 'google-map-script',
    googleMapsApiKey: API_KEY
  });

  if (!isLoaded) return <div>Loading...</div>;

  return <GPSMap />;
}

export default MapLoader;