import React from 'react';
import CssBaseline from '@mui/material/CssBaseline';
import '../App.css';
import {Container, Grid, Typography, Box} from '@mui/material';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import Header from '../components/Header';
import Footer from '../components/Footer';
import Weather from '../components/weather';
const defaultTheme = createTheme();

const HomePage = () => {
  return (
    <ThemeProvider theme={defaultTheme}>
      <Header />
      <CssBaseline />
      <div className="pagebody">
        <Container className='backgroundImage'>
        <main>
            <Grid container justifyContent="center" alignItems="center">
              <Grid item xs={10} md={4} sx={{ textAlign: 'center', marginBottom:'10vh' }}>
                <Typography variant="h6" gutterBottom color='black' sx={{ marginTop: '9vh' }}>
                  We are Candice, Matt, and Maya and we are traveling North America in our Coachman Leprechaun as we search for a place we want to call Hama.
                </Typography>
                {/*<Typography variant="h5" color='black' sx={{ marginTop: '1vh' }}>
                  Current City:</Typography>
                  <Box display="flex" justifyContent="center" alignItems="center" width="100%">
                    <Weather />
                    <Typography variant="h6" color='White' sx={{ marginTop: '1vh' }}>On the Move</Typography>
                  </Box> */}
              </Grid>
            </Grid>
          </main>
        </Container>
      </div>
      <Footer />
    </ThemeProvider>
  );
};

export default HomePage