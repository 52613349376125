import React, { useState, useEffect, useCallback } from 'react';
import { createTheme, ThemeProvider, CssBaseline, Container, Grid } from '@mui/material';
import Header from '../components/Header';
import Footer from '../components/Footer';
import Main from '../components/Main';

const defaultTheme = createTheme();

const fetchPosts = async () => {
  const response = await fetch('/assets/posts/manifest.json');
  const markdownFiles = await response.json();
  return markdownFiles;
};

export default function BlogPage() {
  const [posts, setPosts] = useState([]);

  const getBlogs = useCallback(async () => {
    const markdownFiles = await fetchPosts();
    const allPosts = await Promise.all(
      markdownFiles.map(file =>
        fetch(`/assets/posts/${file}`).then(res => res.text())
      )
    );
    setPosts(allPosts);
  }, []);

  useEffect(() => {
    getBlogs();
  }, [getBlogs]);

  return (
    <ThemeProvider theme={defaultTheme}>
      <CssBaseline />
      <Container maxWidth="lg">
        <Header />
        <main className="pagebody">
          <Grid container spacing={5} sx={{ mt: 5 }}>
            <Main title="From the Campfire" posts={posts} />
          </Grid>
        </main>
      </Container>
      <Footer />
    </ThemeProvider>
  );
}
