import * as React from 'react';
import '../App.css';
import { AppBar, Toolbar, Typography, Button, IconButton, Drawer, List, ListItem } from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';

function Header() {
  const [isDrawerOpen, setIsDrawerOpen] = React.useState(false);

  const toggleDrawer = (open) => (event) => {
    if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
      return;
    }
    setIsDrawerOpen(prevState => !prevState);
  };

  const navButtons = (
    <div className="nav-buttons">
      <Button color="inherit" href="/">Home</Button>
      <Button color="inherit" href="/blog">Blog</Button>
      <Button color="inherit" href="/photos">Photos</Button>
      <Button color="inherit" href="/follow">Our Journey</Button>
    </div>
  );

  return (
    <>
      <AppBar className='appbar'>
        <Toolbar className="toolbar">
          <Typography variant="h6">
            Finding Hama
          </Typography>
          {/* Display nav buttons for larger screens and menu icon for smaller screens */}
          <div style={{ flexGrow: 1 }}></div>
          {window.innerWidth > 600 ? navButtons : (
            <IconButton edge="end" color="inherit" aria-label="menu" onClick={toggleDrawer(true)}>
              <MenuIcon />
            </IconButton>
          )}
        </Toolbar>
      </AppBar>
      {/* Drawer for mobile screens */}
      <Drawer 
          anchor="right" 
          open={isDrawerOpen} 
          onClose={toggleDrawer(true)} 
          variant="temporary"
          PaperProps={{
            style: {
              backgroundColor: '#1976d2',
              color: 'white',
              width: 'auto',
              maxHeight: '225px',
              top: '56px', 
              right: '-5px',
            }
          }}
      >
        <List sx={{ paddingBottom: '2px' }}>
          {['Home', 'Blog', 'Photos', 'Our Journey'].map((text, index) => (
            <ListItem button key={text} onClick={toggleDrawer(true)}>
              <Button color="inherit" href={["/", "/blog", "/photos", "/follow"][index]} fullWidth sx={{ color: 'white' }}>
                {text}
              </Button>
            </ListItem>
          ))}
        </List>
      </Drawer>
    </>
  );
}

export default Header;
