import React from 'react';
import { Typography, Box, Stack, Link, Grid } from '@mui/material';
import '../App.css';
import InstagramIcon from '@mui/icons-material/Instagram';
import CoffeeIcon from '@mui/icons-material/Coffee';
import { FaPatreon } from 'react-icons/fa';

const socialLinksLeft = [
  { name: 'Instagram', icon: InstagramIcon, url:'https://www.instagram.com/findinghama/' },
];
const socialLinksRight = [
  { name: 'Patreon', icon: FaPatreon, url:'https://patreon.com/FindingHama' },
  { name: 'Ko-Fi', icon: CoffeeIcon, url:'https://ko-fi.com/wikimaster' },
];

function Copyright() {
  return (
    <Typography variant="body2" color="text.secondary" align="center">
      {'Copyright © '}
      {new Date().getFullYear()}
      {'.'}
    </Typography>
  );
}

function Footer() {
  return (
    <Box component="Footer" className="footer">
        <Grid container justifyContent="space-between" alignItems="center">
          <Grid item xs={3} md={3}>
          {socialLinksLeft.map((network) => {
              const IconComponent = network.icon;
              return (
                <Link
                  display="block"
                  variant="body2"
                  href={network.url}
                  key={network.name}
                  sx={{ mb: 0.5, textAlign: 'center', fontSize: '0.8em', color:'black' }}
                >
                  <Stack direction="row" spacing={1} alignItems="center" justifyContent="center">
                    <IconComponent sx={{ fontSize: '1em' }} />
                    <span>{network.name}</span>
                  </Stack>
                </Link>
              );
            })}
          </Grid>
          <Grid item xs={6} md={6}>
            <Typography variant="h6" align="center">
              Finding Hama
            </Typography>
            <Copyright />
          </Grid>
          <Grid item xs={3} md={3}>
            {socialLinksRight.map((network) => {
              const IconComponent = network.icon;
              return (
                <Link
                  display="block"
                  variant="body2"
                  href={network.url}
                  key={network.name}
                  sx={{ mb: 0.5, textAlign: 'center', fontSize: '0.8em', color:'black' }}
                >
                  <Stack direction="row" spacing={1} alignItems="center" justifyContent="center">
                    <IconComponent sx={{ fontSize: '1em' }} />
                    <span>{network.name}</span>
                  </Stack>
                </Link>
              );
            })}
          </Grid>
        </Grid>
    </Box>
  );
}

export default Footer;
