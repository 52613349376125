import React from 'react';
import '../App.css';
// import GPSMap from '../components/GPSMap';
import Header from '../components/Header';
import Footer from '../components/Footer';
import { Grid} from '@mui/material';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import MapLoader from '../components/GPSHook'; 

const defaultTheme = createTheme();
const FollowPage = () => {
  return (
    <ThemeProvider theme={defaultTheme}>
      <CssBaseline />
      <Header />
        <main className="pagebody">
          <Grid container justifyContent="center" sx={{ mt: 9 }}>
            <h2>The Route So Far</h2>
            {/* <GPSMap /> */}
            <MapLoader />
          </Grid>
        </main>
      <Footer />
    </ThemeProvider>
  );
};

export default FollowPage;
