import React, { useState, useEffect } from 'react';
import CssBaseline from '@mui/material/CssBaseline';
import { Box, ImageList, ImageListItem, Modal } from '@mui/material';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import Header from '../components/Header';
import Footer from '../components/Footer';
import PaginationButtons from '../components/PageinationButtons';

const defaultTheme = createTheme();

const fetchImages = async () => {
  const response = await fetch('assets/photo_album/manifest.json');
  const imageFiles = await response.json();
  return imageFiles.map(fileName => `assets/photo_album/${fileName}`);
};

export default function Album() {
  const [images, setImages] = useState([]);
  const [open, setOpen] = useState(false);
  const [selectedImage, setSelectedImage] = useState('');
  const [currentPage, setCurrentPage] = useState(0);
  const imagesPerPage = 9;
  const totalPages = Math.ceil(images.length / imagesPerPage);

  useEffect(() => {
    fetchImages().then(setImages);
  }, []);

  const handleNext = () => {
    if (currentPage < totalPages - 1) {
      setCurrentPage(currentPage + 1);
    }
  };

  const handlePrevious = () => {
    if (currentPage > 0) {
      setCurrentPage(currentPage - 1);
    }
  };

  const displayedImages = images.slice(currentPage * imagesPerPage, (currentPage + 1) * imagesPerPage);

  const handleImageClick = (imgPath) => {
    setSelectedImage(imgPath);
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <ThemeProvider theme={defaultTheme}>
      <CssBaseline />
      <Header />
      <main className="pagebody">
        <ImageList sx={{ pt: 13 }} variant="woven" cols={3} gap={2}>
          {displayedImages.map((imgPath, index) => (
            <ImageListItem key={imgPath} onClick={() => handleImageClick(imgPath)}>
              <img
                srcSet={`${imgPath}?auto=format&dpr=2 2x`}
                src={`${imgPath}?auto=format`}
                alt={`Image ${index}`}
                loading="lazy"
              />
            </ImageListItem>
          ))}
        </ImageList>
        <PaginationButtons
          currentPage={currentPage}
          totalPages={totalPages}
          handlePrevious={handlePrevious}
          handleNext={handleNext}
        />
        <Modal
          open={open}
          onClose={handleClose}
          aria-labelledby="modal-selected-photo"
          aria-describedby="modal-expands-selected-image"
        >
          <Box
            sx={{
              position: 'absolute',
              top: { xs: '2.5%', sm: '50%' },
              left: { xs: '2.5%', sm: '50%' },
              transform: { sm: 'translate(-50%, -50%)' },
              bgcolor: 'background.paper',
              boxShadow: 24,
              p: 0.5,
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              width: { xs: '95vw', sm: 'auto' },
              height: { xs: '50vh', sm: '75vh' },
            }}
          >
            <img src={selectedImage} alt="Selected" style={{
              height: '100%',
              width: 'auto',
              maxWidth: '100%',
              objectFit: 'contain'
            }} />
          </Box>
        </Modal>
      </main>
      <Footer />
    </ThemeProvider>
  );
}
