import React, { useState, useEffect, useCallback } from 'react';
import { Button } from '@mui/material';
import Markdown from './Markdown.js';
import { Link } from "react-router-dom";

const ShortenedBlog = ({ post, index, posts }) => {
    const [currentPost, setCurrentPost] = useState();
    const shortBlog = useCallback(() => {
        let snippet = post.slice(0, 300);
        const punctuation = ['.', ',', '!', '?', ';'];
        while (snippet.length < post.length && !punctuation.includes(snippet[snippet.length - 1])) {
          snippet += post[snippet.length];
        }
        setCurrentPost(snippet);
      }, [post]);

    useEffect(() => {
        shortBlog();
    }, [shortBlog]);

    return (
        <>
            {currentPost && (
                <>
                    <Markdown className="markdown" key={index}>{currentPost}</Markdown>
                    <Button variant="contained">
                        <Link 
                            to={`/blog/view`} 
                            state={{ fullPost: posts[index]}}
                            variant="subtitle1" 
                            style={{ color: 'white', textDecoration: 'none' }}
                        >
                            Continue Reading...
                        </Link>
                    </Button>
                </>
            )}
        </>
    )
}
<Button color="inherit" href="/blog/view">Continue Reading...</Button>
export default ShortenedBlog;
