import React, { Component } from 'react';
import { GoogleMap, Polyline, Marker } from '@react-google-maps/api';

const containerStyle = {
  width: '100%',
  height: '720px'
};

const center = {
  lat: 35.149,
  lng: -90.049
};

const mapStyles = [
  {
    "featureType": "administrative.land_parcel",
    "elementType": "all",
    "stylers": [
      { "visibility": "off" }
    ]
  },
  {
    "featureType": "administrative.neighborhood",
    "elementType": "all",
    "stylers": [
      { "visibility": "off" }
    ]
  },
  {
    "featureType": "poi",
    "elementType": "all",
    "stylers": [
      { "visibility": "off" }
    ]
  },
  {
    "featureType": "road",
    "elementType": "all",
    "stylers": [
      { "visibility": "off" }
    ]
  },
  {
    "featureType": "road.highway",
    "elementType": "all",
    "stylers": [
      { "visibility": "on" },
      { "saturation": -75 }
    ]
  },
  {
    "featureType": "transit",
    "elementType": "all",
    "stylers": [
      { "visibility": "off" }
    ]
  },
  {
    "featureType": "water",
    "elementType": "all",
    "stylers": [
      { "visibility": "simplified" },
      { "saturation": -25 }
    ]
  }
];

async function fetchHistoricalCoordinates() {
  try {
    const response = await fetch('https://findinghama.com/pathCoordinates');
    const data = await response.json();
    return data;
  } catch (error) {
    console.error('Error fetching historical coordinates:', error);
    return [];
  }
}

class GPSMap extends Component {
  state = {
    pathCoordinates: [],
    segments: [],
    currentMarker: null,
    mapCenter: center,
  };

  async componentDidMount() {
    await this.processData();
    this.updateMapCenter();

    this.ws = new WebSocket('wss://findinghama.com');
    this.ws.onmessage = this.handleWebSocketMessage;
    this.ws.onerror = this.handleWebSocketError;
    this.ws.onclose = this.handleWebSocketClose;
  }

  componentWillUnmount() {
    if (this.ws) {
      this.ws.close();
    }
  }

  handleWebSocketMessage = (event) => {
    try {
      const gpsData = JSON.parse(event.data);
      const latitude = parseFloat(gpsData.latitude);
      const longitude = parseFloat(gpsData.longitude);
      this.addMarker(latitude, longitude);
    } catch (error) {
      console.error('Error parsing WebSocket message:', error);
    }
  };

  handleWebSocketError = (error) => {
    console.error('WebSocket error:', error);
  };

  handleWebSocketClose = () => {
    console.log('WebSocket connection closed');
  };

  async processData() {
    const historicalData = await fetchHistoricalCoordinates();
    const segments = this.segmentData(historicalData);
    this.setState({ pathCoordinates: historicalData, segments });
  }

  segmentData(data) {
    const segments = [];
    let segment = [];
    data.forEach((point, index) => {
      segment.push(point);
      if ((index + 1) % 250 === 0) {
        segments.push(segment);
        segment = [];
      }
    });
    if (segment.length) {
      segments.push(segment);
    }
    return segments;
  }

  getColorForSegment(index, totalSegments) {
    const midPoint = (totalSegments - 1) / 2;

    const colors = [
      { start: { r: 255, g: 255, b: 0 }, end: { r: 255, g: 165, b: 0 } }, // Yellow to Orange
      { start: { r: 255, g: 165, b: 0 }, end: { r: 255, g: 0, b: 0 } } // Orange to Red
    ];

    const phase = index > midPoint ? 1 : 0;

    const adjustedIndex = phase === 1 ? index - midPoint : index;
    const adjustedTotal = phase === 1 ? totalSegments - midPoint : midPoint;

    const interpolate = (start, end, fraction) =>
      Math.round(start + (end - start) * fraction);

    const fraction = adjustedIndex / (adjustedTotal - 1);

    const r = interpolate(colors[phase].start.r, colors[phase].end.r, fraction);
    const g = interpolate(colors[phase].start.g, colors[phase].end.g, fraction);
    const b = interpolate(colors[phase].start.b, colors[phase].end.b, fraction);

    return `rgb(${r}, ${g}, ${b})`;
  }

  getStrokeWeightForSegment(index, totalSegments) {
    const startWeight = 1.5;
    const endWeight = 5;
    const fraction = index / (totalSegments - 1);
    return startWeight + (endWeight - startWeight) * fraction;
  }

  addMarker(latitude, longitude) {
    this.setState(prevState => ({
      currentMarker: { lat: latitude, lng: longitude },
      pathCoordinates: [...prevState.pathCoordinates, { lat: latitude, lng: longitude }],
    }));
  }

  updateMapCenter() {
    this.setState(prevState => ({
      mapCenter: {
        lat: prevState.mapCenter.lat + 0.0001,
        lng: prevState.mapCenter.lng + 0.0001,
      }
    }));
  }

  render() {
    const { segments, mapCenter, currentMarker } = this.state;
    return (
      <GoogleMap
        mapContainerStyle={containerStyle}
        center={mapCenter}
        zoom={5}
        options={{
          styles: mapStyles,
          minZoom: 5,
          maxZoom: 10
        }}
      >
        {segments.map((segment, index) => (
          <Polyline
            key={index}
            path={segment}
            options={{
              strokeColor: this.getColorForSegment(index, segments.length),
              strokeOpacity: 1,
              strokeWeight: this.getStrokeWeightForSegment(index, segments.length),
            }}
          />
        ))}
        {currentMarker && (
          <Marker
            position={currentMarker}
            title="GPS Location"
            icon="/assets/rv-icon.png"
          />
        )}
      </GoogleMap>
    );
  }
}

export default GPSMap;