import React from 'react';

function PaginationButtons({ currentPage, totalPages, handlePrevious, handleNext }) {
  const isSmallScreen = window.innerWidth < 600;
  return (
    <div style={{display: 'flex', justifyContent: 'space-between', alignItems: 'center', width: '100%', marginTop:'2vh'}}>
      <button 
        onClick={handlePrevious} 
        disabled={currentPage === 0}
        style={{
          fontSize: isSmallScreen ? '0.8rem' : '1.2rem',
          padding: isSmallScreen ? '6px 13px' : '10px 20px', 
          backgroundColor: currentPage === 0 ? '#ccc' : '#1976d2', // Gray when disabled
          color: currentPage === 0 ? '#888' : 'white',
          border: `2px solid ${currentPage === 0 ? '#aaa' : '#1976d2'}`,
          borderRadius: '5px',
          cursor: currentPage === 0 ? 'not-allowed' : 'pointer',
          transition: 'background-color 0.3s ease',
          marginRight: '10px',
          opacity: currentPage === 0 ? 0.6 : 1 // Reduced opacity when disabled
        }}
        onMouseOver={(e) => !e.currentTarget.disabled && (e.currentTarget.style.backgroundColor = '#1565c0')}
        onMouseOut={(e) => !e.currentTarget.disabled && (e.currentTarget.style.backgroundColor = '#1976d2')}
      >
        Previous
      </button>
      <button 
        onClick={handleNext} 
        disabled={currentPage === totalPages - 1}
        style={{
          fontSize: isSmallScreen ? '0.8rem' : '1.2rem',
          padding: isSmallScreen ? '6px 13px' : '10px 20px', 
          backgroundColor: currentPage === totalPages - 1 ? '#ccc' : '#1976d2',
          color: currentPage === totalPages - 1 ? '#888' : 'white',
          border: `2px solid ${currentPage === totalPages - 1 ? '#aaa' : '#1976d2'}`,
          borderRadius: '5px',
          cursor: currentPage === totalPages - 1 ? 'not-allowed' : 'pointer',
          transition: 'background-color 0.3s ease',
          marginLeft: '10px',
          opacity: currentPage === totalPages - 1 ? 0.6 : 1 
        }}
        onMouseOver={(e) => !e.currentTarget.disabled && (e.currentTarget.style.backgroundColor = '#1565c0')}
        onMouseOut={(e) => !e.currentTarget.disabled && (e.currentTarget.style.backgroundColor = '#1976d2')}
      >
        Next
    </button>
    </div>
  );
}
export default PaginationButtons;
