import request from '../request.js';
import  {unitConvert, getTempUnit, getSpeedUnit} from "../unitsConverter.js";

const codeWeather = {
    0: ["Clear", "Clear sky"],
    1: ["Cloudy", "Mainly clear"],
    2: ["Cloudy", "Partly cloudy"],
    3: ["Cloudy", "Overcast"],
    45:["Fog", "Fog"],
    48:["Fog","Depositing rime fog"],
    51:["LightShowerRain","Light drizzle"],
    53:["ShowerRain", "Moderate drizzle"],
    55:["ShowerRain", "Dense drizzle"],
    56:["FreezingRain", "Light freezing drizzle"],
    57:["FreezingRain", "Dense freezing drizzle"],
    61:["LightRain", "Slight rain"],
    63:['ModerateRain', "Moderate rain"],
    65:['ShowerRain', "Heavy rain"],
    66:['FreezingRain', "Light freezing rain"],
    67:['FreezingRain', "Heavy freezing rain"],
    71:['LightSnow',"Slight snow"],
    73:['HeavySnow', "Moderate snow"],
    75:['HeavySnow', "Heavy snow"],
    77:['ShowerSnow', "Snow grains"],
    80:['LightShowerRain', 'Slight shower rain'],
    81:['ShowerRain', 'Moderate shower rain'],
    82:['ShowerRain', 'Violent shower rain'],
    85:['LightShowerSnow', 'Slight shower snow'],
    86:['ShowerSnow', 'Heavy shower snow'],
    95:['Thunderstorm', "Thunderstorm"],
    96:['ThunderstormRain', "Thunderstorm with slight hail"],
    99:['ThunderstormRain', "Thunderstorm with heavy hail"],
}
function isNight(sunrise, sunset, dt) {
    const currentTime = new Date(dt).getTime();
    return currentTime < new Date(sunrise).getTime() || currentTime > new Date(sunset).getTime();
}

function castData(data, opts) {
    const { temperature, windspeed, weathercode } = data.current_weather;
    const currentHour = new Date(data.current_weather.time).getHours();
    const nearestHourTime = `${data.current_weather.time.split('T')[0]}T${String(currentHour).padStart(2, '0')}:00`;
    const currentTimeIndex = data.hourly.time.indexOf(nearestHourTime);

    return {
        location: opts.location,
        isNight: isNight(data.daily.sunrise[0], data.daily.sunset[0], data.current_weather.time),
        temperature: Math.round(unitConvert(temperature + 273.15, opts.tempUnit)),
        humidity: data.hourly.relativehumidity_2m[currentTimeIndex],
        weather_desc: codeWeather[weathercode][1],
        weather_type: codeWeather[weathercode][0],
        feels_like: Math.round(unitConvert(data.hourly.apparent_temperature[currentTimeIndex] + 273.15, opts.tempUnit)),
        wind: Math.round(unitConvert(windspeed, opts.windSpeedUnit) * 10) / 10,
        units: {
            temp: getTempUnit(opts.tempUnit),
            wind: getSpeedUnit(opts.windSpeedUnit)
        }
    };
}

async function localize(opts) {
    const { lat, lon, location, language } = opts;
    let body, url;

    if (lat && lon) {
        body = { params: { latitude: lat, longitude: lon, localityLanguage: language } };
        url = "https://api.bigdatacloud.net/data/reverse-geocode-client";
    } else {
        body = { params: { name: location, language } };
        url = "https://geocoding-api.open-meteo.com/v1/search?count=1";
    }

    const requestOptions = {
        url,
        body,
        followRedirect: true,
        headers: { 'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8' }
    };

    return request(requestOptions);
}

async function openMeteo(opts) {
    if (!opts) return;

    const url = 'https://api.open-meteo.com/v1/forecast?hourly=relativehumidity_2m,apparent_temperature&daily=sunrise,sunset&current_weather=true&windspeed_unit=ms&timezone=Europe%2FLondon';
    const res = await localize(opts);
    console.log(res)
    const { longitude, latitude, city, results } = res;
    const body = {
        params: {
            longitude: longitude || results[0].longitude,
            latitude: latitude || results[0].latitude,
        }
    };

    const requestOptions = {
        url,
        body,
        followRedirect: true,
        headers: { 'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8' }
    };

    opts.location = city || results[0].name;
    const response = await request(requestOptions);
    return castData(response, opts);
}

export default openMeteo;